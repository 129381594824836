/* Overrride QuillForms to allow links click on success/last screen */
.renderer-core-html-parser {
  pointer-events: all !important;
}

/* Overrride QuillForms wrong width not considering negative padding */
/* .renderer-components-field-wrapper .renderer-components-field-wrapper__content-wrapper {
  width: 80% !important;
} */

/* Better display of multiple items */
.renderer-components-field-content {
  max-width: 1050px !important;
}
.renderer-core-block-scroller {
  padding: 40px 0px !important;
}

/* Error displaying next/submit button above the progress bar, not able to click */
.renderer-components-form-footer {
  background: rgba(755, 755, 755, 0.8) !important;
}
.renderer-components-field-content {
  margin-bottom: 100px;
}
